<template>
    <v-layout grid-list-md justify-start pa-0 ma-0 mt-2 mb-3>
        <v-layout row hide-details justify-start ma-0 mb-4>
            <v-flex hide-details mx-4 xs2>
                <v-text-field
                    name="cardnumber"
                    label="Card Ending"
                    id="id"
                    v-model="ccLast4"
                    readonly
                ></v-text-field>
                <a
                    style="text-decoration: none; vertical-align: bottom;"
                    class="mx-4"
                    @click="updateCof"
                >UPDATE CARD ON FILE</a>
            </v-flex>
            
            <v-flex xs10>
                
            </v-flex>
        </v-layout>
    </v-layout>
</template>

<script>
export default {
    name: "SavedCCForm",
    props: ["token", "last4", "BAN"],
    data: () =>({
        ccLast4: ""
    }),
    mounted(){
        this.ccLast4 = "************ " + this.last4;
    },
    methods: {
        updateCof(){
            this.$emit('updatecof');
        }
    }
}
</script>