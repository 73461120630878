<template>
  <v-app id="inspire">
    <v-dialog 
      v-model="dialog"
      fullscreen
      transition="none"
      hide-overlay
    >
        <v-form 
          v-model="valid"
          @submit="updatePayMethod"
          ref="updatePayMethodForm"
        >
            <v-layout justify-start row mx-3 mt-3 align-center>
                <v-flex mb-0 xs12 mx-2>
                    <div
                      class="text-xs-left"
                      style="font-size: 20px; font-weight: 600;"
                    >
                      Edit Payment Method for Account # {{ this.BAN }} <v-progress-circular indeterminate color="primary" v-show="isLoading"></v-progress-circular>
                    </div>
                </v-flex>
                <v-flex>
                  <v-layout row>
                    <v-flex>
                      <v-layout class="buttons mb-0 mr-0" justify-end row>
                        <v-btn icon @click="cancel" class="pa-0 mr-0">
                          <v-icon>fa-times</v-icon>
                        </v-btn>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
            </v-layout>
            <v-layout row mx-3>
              <v-flex xs4 mx-2>
                <v-autocomplete hide-no-data
                  :items="ddlPaymentMethods"
                  v-model="paymentMethodId"
                  label="Payment Method"
                  item-text="Text"
                  item-value="Value"
                  @change="checkForms"
                  clearable
                  hide-details
                  required
                  clear-icon="cancel"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs8>
                
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs12 mt-4>
                <add-cc-form 
                  v-if="showCCForm" 
                  :token="token" 
                  :account="account" 
                  :boss40Account="boss40Account" 
                  @changeccperson="updateCcPerson"
                  @changeccaddress="updateCcAddress"
                  @changeccdetails="updateCcDetails"
                />
                <saved-cc-form v-if="showSavedCC" :token="token" :BAN="BAN" :last4="last4CC"
                  @updatecof="updateCoF"
                />
                <add-stripe-form
                  v-if="showStripeForm"
                  ref="stripeForm"
                  :customer="BAN" 
                  :clientToken="clientToken"
                  :accountName="boss40Account.Name1"
                  :email="boss40Account.billTo_Email"
                  :boss40AppUrl="boss40AppUrl"
                />
                <add-a-c-h-form
                  v-if="showACHForm"
                  ref="achForm"
                  :customer="BAN" 
                  :clientToken="clientToken"
                  :boss40AppUrl="boss40AppUrl"
                />
                <saved-a-c-h-form v-if="showSavedACH" :token="token" :BAN="BAN" :last4="last4ACH"
                  @updateACH="updateACH"
                />
              </v-flex>
            </v-layout>
            <v-layout row justify-end>
              <v-flex xs3>
                <v-layout row justify-end px-0 mx-0 mr-4 align-center>
                <a
                    style="text-decoration: none"
                    class="align-center mx-4"
                    @click="cancel"
                >CANCEL</a>
                <v-btn 
                  color="success" 
                  @click="savePayMethod()" 
                  v-if="!showSavedCC"
                  class="ml-2 mr-0"
                >Save</v-btn>
                <v-btn 
                  color="success" 
                  @click="updatePayMethod()" 
                  v-if="showSavedCC"
                  class="ml-2 mr-0"
                >Update</v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
        </v-form>
    </v-dialog>
  </v-app>
</template>

<script>
import axios from "axios";
import AddCcForm from "../components/AddCCForm.vue"
import SavedCcForm from "../components/SavedCCForm.vue"
import AddStripeForm from "../components/AddStripeForm.vue"
import AddACHForm from "../components/AddACHForm.vue"
import SavedACHForm from "../components/SavedACHForm.vue"
import Bus from '../bus'

export default {
    name: "EditPaymentMethod",
    components: {
      AddCcForm,
      SavedCcForm,
      AddStripeForm,
      AddACHForm,
      SavedACHForm
    },

    data: () => ({
        dialog: true,
        valid: false,
        BAN: "",
        token: "",
        clientToken: "",
        myAccountToken: "",
        paymentMethodId: "CA",
        ddlPaymentMethods: [],
        account: {},
        boss40Account: null,
        isLoading: true,
        last4CC: null,
        last4ACH: null,
        dataKey: "",
        boss40AppUrl: "",
        boss40ApiUrl: "",
        pageTitle: "",
        ccPerson: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: ""
        },
        ccAddress: null,
        ccDetails: null,

        showCCForm: false,
        showACHForm: false,
        showSavedCC: false,
        showSavedACH: false,
        showStripeForm: false,
        pubKey: "",
        clientSecret: "",
        paymentProvider: ""
    }),

    async mounted() {
      this.myAccountToken = this.$route.query.token
      this.deleteQueryString("token")
      await this.decodeMyAccountToken()

      await axios.post(process.env.VUE_APP_PGAPI + 'api/config/getboss40urls',{ "clientToken": this.clientToken })
      .then((resp) => {
        this.boss40AppUrl = resp.data.Boss40AppUrl;
        this.boss40ApiUrl = resp.data.Boss40ApiUrl;
        this.pageTitle = "BOSS40 - " + resp.data.ClientName;
        this.paymentProvider = resp.data.PaymentProvider;
      });

      document.title = this.pageTitle;

      this.loadPaymentMethods();
      await this.hasSavedCC();

      let pubKey = await axios.post(process.env.VUE_APP_PGAPI + "api/values/stripepubkey", {PayGateToken: this.$props.clientToken})
      this.pubKey = pubKey.data
    },

    computed: {
      title: {
        get() {
          return document.title;
        },
        set(val) {
          document.title = val;
        }
      }
    },

    watch: {
      boss40Account: function(val){
        if(val != null)
        {
          this.isLoading = false;
        }
      },
      paymentMethodId: function(val){
        if(val == "CC")
        {
          if (this.last4CC != null && this.last4CC.length == 4)
          {
            this.showSavedCC = true;
            this.showCCForm = false;
          }
          else
          {
            if (this.paymentProvider != "Stripe") {
              this.showSavedCC = false;
              this.showCCForm = true;
            } else {
              this.showSavedCC = false;
              this.showStripeForm = true;
            }
          }
        }
        else
        {
          this.showSavedCC = false;
          this.showCCForm = false;
        }
      }
    },

    methods: {
        async updatePayMethod() {
          if(this.paymentMethodId == "CC") {
            var wait4HasCC = this.hasSavedCC();

            if(wait4HasCC!= null && wait4HasCC.length == 4)
            {
              var wait4Delete = await this.deleteSavedCC();
              console.log(wait4Delete);
            }
            else
            {
              this.ccPerson = {};
              this.ccAddress = {};
              this.ccDetails = {};
              this.last4CC = "";
            }

            this.showCCForm = true;
            this.showSavedCC = false;
          } else if(this.paymentMethodId == "AC") {
            this.showACHForm = true;
            this.showSavedACH = false;
          }
        },
        updateCcPerson(data){
          this.ccPerson = data;
        },
        updateCcAddress(data){
          this.ccAddress = data;
        },
        updateCcDetails(data){
          this.ccDetails = data;
        },
        updateCoF(){
          console.log('updateCoF');
          if(this.paymentMethodId == "CC" && this.paymentProvider != "Stripe")
            this.showCCForm = true;
          else if(this.paymentMethodId == "ST" || this.paymentProvider == "Stripe")
            this.showStripeForm = true;
          this.showSavedCC = false;
        },
        updateACH(){
          console.log('updateACH')
          this.showACHForm = true
          this.showSavedACH = false
        },

        deleteQueryString(queryString){
          let query = Object.assign({}, this.$route.query);
          delete query[`${queryString}`];
          this.$router.replace({ query });
        },
        async savePayMethod() {
          let config = {
            headers: {
              'Authorization': 'Bearer ' + this.token
            }
          }

          // Update payment method back to BOSS40
          
          this.boss40Account.paymentMethodId = this.paymentMethodId;
          await axios.post(this.boss40ApiUrl + 'api/Account/EditAccount', this.boss40Account, config)
          .then(() => {
            console.log("Saving payment method back to BOSS40");
          })
          .catch((err) => {
            console.error(err);
            // client token is likely expired
            // redirect back to login screen of BOSS40
            window.document.location = this.boss40AppUrl + "#/login";
          });

          if(this.paymentMethodId == "CC"  && this.paymentProvider != "Stripe")
          {
            let cardDetails = {}
            if (this.paymentProvider == "Paytrace") {
              cardDetails = {
                payGateToken: this.clientToken,
                BAN: this.BAN,
                paytraceCustomer: {
                  customer_id: this.BAN,
                  credit_card: {
                    number: this.ccDetails.ccNumber,
                    expiration_month: this.ccDetails.ccExpiry.substring(5, 7),
                    expiration_year: this.ccDetails.ccExpiry.substring(2, 4)
                  },
                  billing_address: {
                    street_address: `${this.ccAddress.streetNumber} ${this.ccAddress.streetName}`,
                    zip: this.ccAddress.postalZip,
                    country: this.ccZipPostalCode.length == 6 ? "CA" : "US" 
                  }
                }
              }
              this.last4CC = this.ccDetails.ccNumber.substring(12, 16)
            } else {
              cardDetails = {
                payGateToken: this.clientToken,
                BAN: this.BAN,
                ccPerson: this.ccPerson,
                ccDetails: this.ccDetails,
                ccAddress: this.ccAddress
              }
            }

            await axios.post(process.env.VUE_APP_PGAPI + "api/card/post", cardDetails)
            .then((res) => {
              if (res.status == 200) {
                this.dataKey = res.data.Result.dataKey
                this.last4CC = res.data.Result.last4
              } else {
                Bus.$emit('alert', {
                  color: 'error',
                  message: `There was an error saving your card details.`
                })
              }
            })

            await this.hasSavedCC();
          }
          else if(this.paymentMethodId == "ST" || (this.paymentProvider == "Stripe" && this.paymentMethodId == "CC"))
          {
            var accountName = this.boss40Account.Name1
            await axios.post(process.env.VUE_APP_PGAPI + "api/stripe/secret",{
              "BAN": this.BAN,
              "Name": accountName,
              "Email": this.boss40Account.billTo_Email,
              "client_token": this.clientToken
            })
            await this.$refs.stripeForm.submit()
            return
          }
          else if(this.paymentMethodId == "AC")
          {
            await this.$refs.achForm.submit()
            return
          }
          else
          {
            console.log("updating non CC");
            var wait4HasCC = await this.hasSavedCC();
            console.log(wait4HasCC);

            if(this.last4CC != null && this.last4CC.length == 4)
            {
              console.log("calling delete of saved key");
              var wait4delete = await this.deleteSavedCC();
              console.log(wait4delete);
            }
          }

          window.document.location = this.boss40AppUrl + '#/account-summary/' + this.BAN + "?update=true";
        },
        cancel() {
          window.document.location = this.boss40AppUrl + '#/account-summary/' + this.BAN;
        },
        async loadPaymentMethods() {
          let config = {
            headers: {
              'Authorization': 'Bearer ' + this.token
            }
          }

          if(this.token+" " != " ")
          {
            axios.get(this.boss40ApiUrl + 'api/Account/GetEditAccountDDls',config)
            .then((res) => {
              this.ddlPaymentMethods = res.data.ddlPaymentMethods;
              this.loadAccount();
            })
            .catch((error) => {
              console.error(error);
            });
          }
        },
        async loadAccount() {
          let config = {
            headers: {
              'Authorization': 'Bearer ' + this.token
            }
          }

          if(this.token+" " != " ")
          {
            axios.get(this.boss40ApiUrl + 'api/Account/GetEditAccountInfo/' + this.BAN,config)
            .then((res) => {
              this.boss40Account = res.data;
              this.paymentMethodId = res.data.paymentMethodId;
              this.checkForms();
            })
            .catch((error) => {
              console.error(error);
            });
          }
        },
        async checkForms() {
          this.showStripeForm = false;

          switch (this.paymentMethodId) {
            case "CC":
              // Need to lookup if we already have a saved CC
              this.hasSavedCC();
              break
            case "ST":
              // Stripe vault (CC) was selected
              this.showStripeForm = true;
              // See if we have a saved CC
              await this.hasSavedCC();
              if(this.last4CC.length == 4)
              {
                this.showStripeForm = false;
                this.showSavedCC = true;
              }
              break
            case "AC":
              await this.hasSavedACH()
              break
            default:
              this.last4CC = "";
              break
          }
        },
        async hasSavedCC() {
          var BAN = this.BAN;
          var PayGateToken = this.clientToken;
          var postData = { BAN, PayGateToken };

          var resp = await axios.post(process.env.VUE_APP_PGAPI + 'api/values/getkey' , postData);
          
          this.last4CC = resp.data;
        },
        async hasSavedACH() {
          var BAN = this.BAN
          var PayGateToken = this.clientToken
          var postData = { BAN, PayGateToken }
          await axios
            .post(process.env.VUE_APP_PGAPI + 'api/ach/read' , postData)
            .then(resp => {
              this.last4ACH = resp.data.Result.accountNumber
              this.showSavedACH = true
            })
            .catch(error => {
              console.log(error)
              this.showACHForm = true
            })
        },
        async deleteSavedCC() {
          console.log("deleteSavedCC");
          var BAN = this.BAN;
          var PayGateToken = this.clientToken;

          var postData = { BAN, PayGateToken };

          var result = await axios.post(process.env.VUE_APP_PGAPI + 'api/values/deletekey', postData);

          console.log(result);

          this.hasSavedCC();
        },
        async decodeMyAccountToken(){
          await axios
            .post(process.env.VUE_APP_PGAPI + `api/boss40/DecodeMyAccountToken/?token=${this.myAccountToken}`)
            .then((resp) => {
              this.clientToken = resp.data.PaygateToken
              this.BAN = resp.data.AccountNumber
              this.token = resp.data.BearerToken
            })
        },
    }
}
</script>

<style scoped>
.buttons-bottom {
  position: fixed;
  bottom: 40px;
  right: 500px;
}

#pdf {
  overflow: hidden;
  height: 0;
}
#qaPdf {
  overflow: hidden;
  height: 0;
}
#pictureshidden {
  overflow: hidden;
  height: 0;
}
.TierButton {
  border-color: #4caf50 !important;
  border-width: 1px !important;
  border-style: solid !important;
  color: #4caf50 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.TierButton:hover {
  background-color: #4caf50 !important;
  color: white !important;
}
.status-colour >>> .v-text-field__slot input {
  color: var(--status-colour);
}
</style>