<template>
    <v-container class="content" fluid pa-0 fill-height>
      <v-layout row fill-height class="justify-center text-xs-center">
        <v-spacer></v-spacer>
        <v-flex xs3></v-flex>
        <v-flex xs6>
          <v-card flat>
            <v-card-text style="height:100%">
              <v-form
                v-model="valid"
                @submit="submit"
                ref="saveACHForm"
                lazy-validation
                style="height:100%"
              >
                <v-layout row text-xs-left title>
                  <v-flex xs12>
                    Bank Details
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs6 mr-2>
                    <v-text-field
                      name="accountHolderName"
                      v-model="accountHolderName"
                      :rules="requiredRule"
                      ><template slot="label">
                        Account Holder Name<red-asterisk></red-asterisk></template>
                    </v-text-field>
                  </v-flex>
                  <v-flex xs6 ml-2>
                    <v-select
                      label="Account Type"
                      :items="accountHolderTypes"
                      v-model="accountHolderType"
                      autocomplete
                    ></v-select>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs6 mr-2>
                    <v-text-field
                      name="bankAccountNumber"
                      v-model="bankAccountNumber"
                      :rules="accountNumberRule"
                      ><template slot="label">
                        Account Number<red-asterisk></red-asterisk></template>
                    </v-text-field>
                  </v-flex>
                  <v-flex xs6 ml-2>
                    <v-text-field
                      name="bankRoutingNumber"
                      v-model="bankRoutingNumber"
                      :rules="routingNumberRule"
                      :mask="routingNumberMask"
                      ><template slot="label">
                        Routing Number<red-asterisk></red-asterisk></template>
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs6 mr-2>
                    <v-text-field
                      name="email"
                      v-model="email"
                      :rules="emailRules"
                      ><template slot="label">
                        Account Holder Email<red-asterisk></red-asterisk></template>
                    </v-text-field>
                  </v-flex>
                  <v-flex xs6 ml-2>
                    <v-text-field
                      name="phoneNumber"
                      v-model="phoneNumber"
                      :rules="requiredRule"
                      :mask="phoneNumberMask"
                      ><template slot="label">
                        Phone Number<red-asterisk></red-asterisk></template>
                    </v-text-field>
                  </v-flex>
                </v-layout>
                </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs3></v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import axios from "axios"

export default {
    props: {
        customer: String,
        clientToken: String,
        boss40AppUrl: String,
    },
    data() {
        return {
            valid: false,
            menu: false,
            email: "",
            phoneNumber: "",
            accountHolderName: "",
            accountHolderType: "BUSINESS_CHECKING",
            bankAccountNumber: "",
            bankRoutingNumber: "",
            accountHolderTypes: [
              {value: "BUSINESS_CHECKING", text: "Business Checking"},
              {value: "BUSINESS_SAVINGS", text: "Business Savings"},
              {value: "PERSONAL_CHECKING", text: "Personal Checking"},
              {value: "PERSONAL_SAVINGS", text: "Personal Savings"}
            ],
            phoneNumberMask: "(###) ###-####",
            routingNumberMask: "#########",
            emailRules: [
              (v) => !!v || "Required",
              (v) =>
                /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                  v
                ) || "E-mail must be valid",
            ],
            accountNumberRule: [(v) => v.length > 5 && v.length < 18 || "Account Number must be 6 to 17 digits long."],
            routingNumberRule: [(v) => v.length == 9 || "Routing Number must be 9 digits."],
            requiredRule: [(v) => !!v || "Required"],
        }
    },
    methods: {
        async submit(){
          if (this.$refs.saveACHForm.validate()) {
            axios.post(process.env.VUE_APP_PGAPI + "api/ach/create" ,
            { 
              payGateToken: this.$props.clientToken,
              BAN: this.$props.customer,
              email: this.email,
              quickbooksACH: {
                phone: this.phoneNumber,
                name: this.accountHolderName,
                accountType: this.accountHolderType,
                accountNumber: this.bankAccountNumber,
                routingNumber: this.bankRoutingNumber,
                default: true
              }
            })
            .then(res => {
                if (res.status == 200) location.assign(`${this.$props.boss40AppUrl}?#/account-summary/${this.$props.customer}?update=true`)
              })
            .catch((error) =>{
                console.log(error);
            });
          }
        }
    }
}
</script>