<template>
    <v-layout grid-list-md justify-start pa-0 ma-0 mt-2 mb-3>
        <v-layout row hide-details justify-start ma-0 mb-4>
            <v-flex hide-details mx-4 xs2>
                <v-text-field
                    name="accountNumber"
                    label="Account Number"
                    v-model="accountNumber"
                    readonly
                ></v-text-field>
                <a
                    style="text-decoration: none; vertical-align: bottom;"
                    class="mx-4"
                    @click="updateACH"
                >Add New Bank Details</a>
            </v-flex>
            
            <v-flex xs10>
                
            </v-flex>
        </v-layout>
    </v-layout>
</template>

<script>
export default {
    name: "SavedACHForm",
    props: ["token", "last4", "BAN"],
    data: () =>({
        accountNumber: ""
    }),
    mounted(){
        this.accountNumber = this.last4
    },
    methods: {
        updateACH(){
            this.$emit('updateACH')
        }
    }
}
</script>