<template>
    <v-container class="content" fluid pa-0 fill-height>
      <v-layout row fill-height class="justify-center text-xs-center">
        <v-spacer></v-spacer>
        <v-flex xs3></v-flex>
        <v-flex xs6>
          <v-card flat>
            <v-card-text style="height:100%">
              <v-form
                v-model="valid"
                @submit="submit"
                ref="saveCardForm"
                lazy-validation
                style="height:100%"
              >
                <v-layout row text-xs-left title>
                  <v-flex xs12>
                    Credit Card
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-text-field
                    name="ccNumber"
                    prepend-inner-icon="fa-credit-card"
                    v-model="ccNumber"
                    mask="credit-card"
                    placeholder="1234 - 5678 - 90123 - 4567"
                    :rules="cardNumberRules"
                    ><template slot="label">
                      Credit Card Number<red-asterisk></red-asterisk></template
                  ></v-text-field>
                </v-layout>
                <v-layout row>
                  <v-flex xs3>
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <v-text-field
                        style="max-height: 44px"
                        slot="activator"
                        v-model="ccExpiry"
                        prepend-inner-icon="fa-calendar-alt"
                        placeholder="YYYY-MM"
                        readonly
                        required
                        validate-on-blur
                        :rules="expiryRules"
                        ><template slot="label">
                          Expiry Date<red-asterisk></red-asterisk></template
                      ></v-text-field>
                      <v-date-picker
                        v-model="ccExpiry"
                        type="month"
                        @input="menu = false"
                        :month-format="getMonths"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                </v-layout>
                </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs3></v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import axios from "axios"

export default {
    props: {
        customer: String,
        clientToken: String,
        boss40AppUrl: String,
    },
    data() {
        return {
            valid: false,
            menu: false,
            ccNumber: null,
            ccExpiry: null,
            ccMonth: null,
            ccYear: null,

            cardNumberRules: [
                v => !!v || "Card Number is required",
                v => /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|(222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11}|62[0-9]{14})$/
                  .test(v) || "Invalid Card Number",
            ],
            expiryRules: [
              (v) => !!v || "Expiry is required",
              (v) => /^[0-9\\-]{7}$/.test(v) || "Invalid Year/Month",
            ],
            months: [
              "02 - FEB",
              "03 - MAR",
              "04 - APR",
              "05 - MAY",
              "06 - JUN",
              "07 - JUL",
              "08 - AUG",
              "09 - SEP",
              "10 - OCT",
              "11 - NOV",
              "12 - DEC",
              "01 - JAN",
            ],
        };
    },
    watch: {
        ccExpiry() {
            this.ccMonth = this.ccExpiry.substring(5, 7)
            this.ccYear = this.ccExpiry.substring(2, 4)
        }
    },
    methods: {
        async submit(){
          if (this.$refs.saveCardForm.validate()) {
            axios.post(process.env.VUE_APP_PGAPI + "api/stripe/complete" , 
            { 
                cardNumber: this.ccNumber,
                cardExpiryMonth: this.ccMonth,
                cardExpiryYear: this.ccYear,
                customer: this.$props.customer,
                client_token: this.$props.clientToken,
            })
            .then(res => {
                if (res.status == 200) location.assign(`${this.$props.boss40AppUrl}?#/account-summary/${this.$props.customer}?update=true`)
            })
            .catch((error) =>{
                console.log(error);
            });
          }
        },
        getMonths(month) {
          let i = new Date(month).getMonth(month)
          return this.months[i]
        },
    }
}
</script>
<style scoped>
.card-title{
    font-family: Roboto, sans-serif;
    font-size: 20px;
}
.form-field-label{
    color: grey;
    font-family: Roboto, sans-serif;
    font-size: 12px;
}
.form-field{
    border-bottom-width: 1px;
    border-bottom-color: lightgray;
    border-bottom-style: solid;
}
</style>